@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";


.up-1-item-carousel {
    margin-bottom: 10px;
}

/* stylelint-disable-next-line */
.experience-layouts-up1ItemCarousel .margins-enabled {
    margin-top: 15px;

    @include media-breakpoint-up(md) {
        margin-top: 32px;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 21px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 31px;
    }
}

.up-1-item-carousel .up-1-item-carousel-carousel-wrapper .swiper-pagination {
    align-items: center;
    bottom: 28%;
    display: flex;
    justify-content: center;
    left: 12px;
    top: auto;

    @include media-breakpoint-up(md) {
        align-items: center;
        bottom: 8px;
        display: flex;
        justify-content: center;
        left: 0;
        top: auto;
    }
}

.up-1-item-carousel-carousel-wrapper {
    position: relative;

    .hide-arrow {
        display: none;

        @include media-breakpoint-up(lg) {
            display: flex;
        }
    }

    .swiper-slide {
        width: 100%;
    }
}
